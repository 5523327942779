import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Sean from '../../assets/sean_face.png'
import { Ring } from 'react-spinners-css';

export default function Login(props) {

    const [newUser, setNewUser] = useState(true)
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const {login, signup, sessionErrors} = props
    const [selectedArtist, setSelectedArtist] = useState(null)
    const artists = ["Nikky Altemose", "Olivia Torres", "Sean Altemose"]

    useEffect(() => {
        window.scrollTo(0,3)
    }, [])

    useEffect(() => {
        if (sessionErrors) {
            setLoggingIn(false)
        }
    }, [sessionErrors])

    function handleSubmit(e) {
        e.preventDefault();
    
        let user = {
          email: email.toLowerCase(),
          password: password
        };

        setLoggingIn(true)
    
 
            login(user); 

        
      }

      function selectArtist(artist) {
        if (artist) {
        if (selectedArtist !== artist) { setSelectedArtist(artist) } }
        else { setSelectedArtist(null) }
      }

      function setEmailField(selectedArtist) {
        switch(selectedArtist) {
            case "Nikky Altemose":
              setEmail("nikkyaesthetics@gmail.com")
              break;
            case "Olivia Torres":
              setEmail("livtorrbmr@gmail.com")
              break;
            case "Shery Velasco":
              setEmail("sherrymaycosmetics@gmail.com")
              break;
            case "Makayla Pfeifer":
              setEmail("makayla.pfeifer@gmail.com")
              break;
            case "Sean Altemose":
                setEmail("sean@seanaltemose.com")
                break;
            default:
              // code block
          }
      }

      useEffect(() => {
        if (selectedArtist) {
            setEmailField(selectedArtist)
        }
    }, [selectedArtist])

        return (
            <div className="full-body">
                    <div className='slide-in-bottom'>
                <div className="login-header">
                <Link to ="/"><div className="nav-btn"><i class="fa fa-chevron-left"></i> Back</div></Link>
                </div>

                <div className="login-box">
                <div className="login-title wht c">Student Login</div>
     
                <div className="login-form-container">
   
                <div className={newUser ? "login-form login-box c" : "hidden"}>
                    <form onSubmit={handleSubmit}>
                        <input className="" onChange={(e) => setEmail(e.currentTarget.value)}  type="username" placeholder="Username"></input>
                        <input className="" onChange={(e) => setPassword(e.currentTarget.value)}  type="password" placeholder="Pin"></input>
                        {sessionErrors ? 
                        <div>{Object.keys(sessionErrors).map((key) => {
                            return (
                                <div className="error">{sessionErrors[key]}</div>
                            )
                        }) 
                        }</div> : null }
                        <input className={password !== '' ? "submit" : "submit-no"} value={"Let's Go"} type="submit"></input>
                    </form>
                </div>
                </div>
                </div>
            </div>
                <div className={loggingIn ? "overlay" : "hidden"}>
                    <div className={loggingIn ? "ring" : "hidden"}>
                        <Ring className="ring" color="#fff" size={30} />
                    </div> 
                </div>
            </div>
        )
    }
import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function MainPage(props) {

  const [open, setOpen] = useState(null)

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }
  

    return (
      <div className="main-body fade-in">
        <div className="main-page">
          
        <div className="landing-page">
          <br/>
          <br/>
          <br/>

                    <div className="entry-text fade-in">
                    <div className="entry-text-o down-60">The Ombré Powder Brows<br/>Full Introductory Course</div>
        

                    <div className=""> 
                    <div className="logo-layer-1">
                      <img src={Logo2}></img>
                    </div>
                    <div className="logo-layer-2">
                      <img src={Logo1}></img>
                    </div>
                    </div>
                    <div className="button-background">
                    </div>
                    <Link className="btnlogin" to='/login'>
                    <button className="get-started2 moving-gradient"><span className="btn-text">Log In</span></button>
                    </Link>
                    </div>
        </div>
        </div></div>
        );
    }